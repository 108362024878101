/*******************  DEFAULT VARIABLES ***************/
$defaultFont        : 'PT Sans', sans-serif;
$defaultTexteColor  : #6C757A;
$defaultLinkColor   : inherit;
$defaultBgcolor     : #ffffff;
$defaultFontSize    : 1.5em;
$defaultFontWeight  : 400;

/*******************  VARIABLES ***************/
$satisfy: 'Satisfy', 'cursive';
$asap: 'PT Sans', sans-serif;
$projetim: 'icomoon';
$fa: 'FontAwesome';


$blanc : #ffffff;
$noir: #000000;
$bleu: #00ABC5;
$bleu2 : #007180;
$bleu3 : #368EE0;
$fdgris: #ecebe7;
$gris: #6C757A;
$grisf: #576167;
$gris3 : #4B5459;
$gris4: #D8D5D1;
$gris5: #B0AFAB;
$violet: #8F4579;
$violet2: #9887C9;
$violet3: #753964;
$apricot: #E87D7D;
$jaune: #BFA000;
$jaune2: #E5C31A;
$vert: #45C497;

$wrap : 1280px;
$marge: calc((100% - #{$wrap}) / 2);
