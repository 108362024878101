/*******************  NORMALIZE ***********************/
html{
    margin:0;
    padding:0;
    font: normal 62.5% $defaultFont, Arial, Verdana, Helvetica, sans-serif;
    color: $defaultTexteColor;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
body{
    font-size: $defaultFontSize;
    font-family: $defaultFont;
    margin:0;
    padding:0;
    background-color:$defaultBgcolor;
    width:100%;
    color: $defaultTexteColor;
    height: 100%;
    font-weight: $defaultFontWeight;
}
h1, h2, h3, h4, h5, h6, p, ul, ol, li, a, span, strong, em, u, small, div, table, tbody, tr, td, form, input, select, textarea, fieldset, label, option{
    margin:0;
    padding:0;
    font-size: 1em;
    text-decoration: none;
    font-weight: $defaultFontWeight;
    box-sizing: border-box;
}
b, strong{
    font-weight: 700;
}
a{
    color: $defaultLinkColor;
    cursor: pointer;

    &:focus,
    &:hover
    &:active{
        outline: 0;
    }
}
a:hover{
    -webkit-text-decoration-skip: objects;
    text-decoration: none;
}
img{
    margin:0;
    padding:0;
    border:none;
    vertical-align: middle;
}
sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    box-sizing: border-box;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
td,
th {
  padding: 0;
}
small {
  font-size: 80%;
}
svg:not(:root) {
  overflow: hidden; //Hide the overflow in IE.
}
[hidden] {
  display: none;
}


/*******************  FORMS ***************************/
button,
input,
optgroup,
select,
textarea {
  font-family: $defaultFont;
  font-size: 1.6rem;
  line-height: 1.15;
  margin: 0;
}
textarea{
    resize:vertical;
}
input{
    cursor: pointer;
}
button,
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

/*******************  HELPER CLASSES ******************/

/*
 * Hide visually and from screen readers
 */

.hidden {
    display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

body.preload * {
    transition: none !important;
}
