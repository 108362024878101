/*******************  DEFAULT VARIABLES ***************/
/*******************  VARIABLES ***************/
/*******************  NORMALIZE ***********************/
html {
  margin: 0;
  padding: 0;
  font: normal 62.5% "PT Sans", sans-serif, Arial, Verdana, Helvetica, sans-serif;
  color: #6C757A;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-size: 1.5em;
  font-family: "PT Sans", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  width: 100%;
  color: #6C757A;
  height: 100%;
  font-weight: 400; }

h1, h2, h3, h4, h5, h6, p, ul, ol, li, a, span, strong, em, u, small, div, table, tbody, tr, td, form, input, select, textarea, fieldset, label, option {
  margin: 0;
  padding: 0;
  font-size: 1em;
  text-decoration: none;
  font-weight: 400;
  box-sizing: border-box; }

b, strong {
  font-weight: 700; }

a {
  color: inherit;
  cursor: pointer; }
  a:focus,
  a:hover a:active {
    outline: 0; }

a:hover {
  -webkit-text-decoration-skip: objects;
  text-decoration: none; }

img {
  margin: 0;
  padding: 0;
  border: none;
  vertical-align: middle; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  box-sizing: border-box; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

small {
  font-size: 80%; }

svg:not(:root) {
  overflow: hidden; }

[hidden] {
  display: none; }

/*******************  FORMS ***************************/
button,
input,
optgroup,
select,
textarea {
  font-family: "PT Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 1.15;
  margin: 0; }

textarea {
  resize: vertical; }

input {
  cursor: pointer; }

button,
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

/*******************  HELPER CLASSES ******************/
/*
 * Hide visually and from screen readers
 */
.hidden {
  display: none !important; }

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

body.preload * {
  transition: none !important; }

.noscroll {
  overflow: hidden; }

.left {
  float: left; }

.right {
  float: right; }

main section, main article {
  padding: 0 30px; }
  main section p, main article p {
    margin-bottom: 15px; }
  main section a, main article a {
    color: #00ABC5; }
    main section a:hover, main article a:hover {
      text-decoration: underline; }
  main section ul, main article ul {
    margin-bottom: 15px; }

main section article {
  padding: 0; }

.titre1 {
  font-family: "Satisfy", "cursive";
  font-size: 6rem;
  line-height: 7.1rem;
  color: #ffffff;
  font-weight: 400; }

.titre2, h1,
p.prix {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.6rem;
  margin-bottom: 5px; }

.titre2-invest {
  color: #8F4579;
  font-family: "Satisfy", "cursive";
  font-size: 3.2rem;
  line-height: 3.8rem;
  font-weight: 400;
  margin-bottom: 20px; }

.titre3 {
  font-family: "Satisfy", "cursive";
  font-size: 3.2rem;
  line-height: 3.8rem;
  font-weight: 400; }

.titre4 {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 2.7rem;
  margin-bottom: 10px;
  text-transform: uppercase; }

.titre5 {
  font-size: 2.25em;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 25px; }

.titre6 {
  font-size: 1.95rem;
  font-weight: 700; }

.legende {
  color: #B0AFAB;
  margin-bottom: 25px;
  font-size: 1.4rem;
  margin-top: -5px; }

.citation {
  font-style: italic;
  font-size: 1.8rem;
  border-left: 2px solid #00ABC5;
  padding-left: 20px;
  line-height: 2.6rem;
  margin: 20px 0 20px 40px; }

.exergue {
  font-size: 2rem;
  line-height: 2.8rem; }

.erreur {
  font-size: 1.7em;
  text-align: center;
  padding: 25px 0;
  font-family: "Satisfy", "cursive"; }

.btn {
  font-size: 1.4rem;
  padding: 8px 18px 8px 18px;
  border: 1px solid #6C757A;
  display: inline-block;
  text-transform: uppercase;
  margin-top: 18px;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  z-index: 10; }
  .btn:before {
    content: '';
    position: absolute;
    top: 0;
    right: -50px;
    bottom: 0;
    left: 0;
    border-right: 50px solid transparent;
    border-bottom: 80px solid #ffffff;
    transform: translateX(-100%);
    transition: 0.3s;
    z-index: -1; }
  .btn.btn-bleu {
    background-color: #00ABC5;
    border-color: #00ABC5;
    color: #ffffff;
    transition: 0.2s; }
    .btn.btn-bleu:hover {
      background-color: #007f92;
      border-color: #007f92;
      color: #00ABC5; }
  .btn.btn-blanc {
    border-color: #ffffff;
    color: #ffffff; }
    .btn.btn-blanc:hover:before {
      border-bottom-color: #ffffff; }
  .btn.btn-gris {
    border-color: #6C757A;
    color: #6C757A; }
    .btn.btn-gris:hover {
      color: #ffffff; }
      .btn.btn-gris:hover:before {
        border-bottom-color: #6C757A; }
  .btn:hover {
    text-decoration: none; }
    .btn:hover:before {
      transform: translateX(0); }

.violet a .titre,
.violet a:before {
  color: #8F4579; }

.violet .btn:hover {
  color: #ffffff;
  border-color: #8F4579; }

.violet .btn:hover:before {
  border-bottom-color: #8F4579; }

.jaune a .titre,
.jaune a:before {
  color: #BFA000; }

.jaune .btn:hover {
  color: #ffffff;
  border-color: #BFA000; }

.jaune .btn:hover:before {
  border-bottom-color: #BFA000; }

.bleu a .titre,
.bleu a:before {
  color: #00ABC5; }

.bleu .btn:hover {
  color: #ffffff;
  border-color: #00ABC5; }

.bleu .btn:hover:before {
  border-bottom-color: #00ABC5; }

.vert a .titre,
.vert a:before {
  color: #45C497; }

.vert .btn:hover {
  color: #ffffff;
  border-color: #45C497; }

.vert .btn:hover:before {
  border-bottom-color: #45C497; }

.fd_titre {
  padding: 56px 15px 30px;
  margin-bottom: 30px;
  text-align: center;
  color: #576167;
  z-index: 10;
  margin-top: -30px;
  background-color: #ecebe7; }
  .fd_titre .titre2, .fd_titre h1,
  .fd_titre p.prix {
    color: #576167; }

.front .fd_titre {
  position: absolute;
  left: -9999px; }

.txt-bleu {
  color: #00ABC5; }

.row {
  display: table;
  width: 100%; }
  .row .col {
    display: table-cell; }

.flex {
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
  justify-content: space-between; }

.flex-item {
  display: inline-block;
  list-style-type: none;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.bg-blanc {
  background-color: #ffffff; }

.bg-jaune {
  background-color: #BFA000; }

.bg-bleu {
  background-color: #00ABC5; }

.bg-gris {
  background-color: #ecebe7; }

.bg-violet {
  background-color: #8F4579;
  color: #ffffff; }

[class*="pattern-"],
.fd_titre {
  position: relative; }
  [class*="pattern-"]:before,
  .fd_titre:before {
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    content: '';
    display: inline-block;
    background-repeat: repeat-x;
    background-position: left top; }
  [class*="pattern-"]:after,
  .fd_titre:after {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    content: '';
    display: inline-block;
    background-repeat: repeat-x;
    background-position: left bottom; }

.pattern-gris-top:before {
  background-image: url(../images/pattern-gris-top.svg); }

.pattern-gris-bottom:after,
.fd_titre:after {
  background-image: url(../images/pattern-gris-bottom.svg); }

.pattern-jaune-top:before {
  background-image: url(../images/pattern-jaune-top.svg); }

.pattern-jaune-bottom:after {
  background-image: url(../images/pattern-jaune-bottom.svg); }

.pattern-blanc-top:before {
  background-image: url(../images/pattern-blanc-top.svg); }

.pattern-blanc-bottom:after {
  background-image: url(../images/pattern-blanc-bottom.svg); }

.pattern-bleu-top:before {
  background-image: url(../images/pattern-bleu-top.svg); }

.pattern-bleu-bottom:after {
  background-image: url(../images/pattern-bleu-bottom.svg); }

[class*="barre-"],
.barre-horizontale {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #aeb4b7;
  left: 0;
  z-index: 100; }
  [class*="barre-"]:before, [class*="barre-"]:after,
  .barre-horizontale:before,
  .barre-horizontale:after {
    position: absolute;
    top: -3.5px;
    width: 8px;
    height: 8px;
    background-color: #aeb4b7;
    content: '';
    display: inline-block;
    border-radius: 100%; }
  [class*="barre-"]:before,
  .barre-horizontale:before {
    left: 0; }
  [class*="barre-"]:after,
  .barre-horizontale:after {
    right: 0; }

.picto-station {
  background-color: #00ABC5;
  border-radius: 100%;
  color: #ffffff;
  text-transform: uppercase;
  display: inline-block;
  width: 15px;
  text-align: center;
  padding: 2px 0;
  font-size: 0.95rem;
  font-weight: 700;
  margin-top: 5px; }
  .picto-station.mA {
    background-color: #E87D7D; }
  .picto-station.mB {
    background-color: #BFA000; }
  .picto-station.mC {
    background-color: #9887C9; }

.hide {
  display: none; }

.nopadding {
  padding: 0; }

.pager {
  text-align: center;
  margin-bottom: 50px; }
  .pager li {
    list-style-type: none;
    display: inline-block;
    border-left: 1px solid #ecebe7;
    font-size: 1.2rem;
    color: #00ABC5;
    text-align: center;
    padding: 2px 10px; }
    .pager li:nth-child(1), .pager li.pager-prev, .pager li.pager-last {
      border-color: #ffffff; }
    .pager li.pager-current {
      color: #ecebe7; }

.camembert {
  background-image: url(../images/camembert.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 60px;
  height: 60px;
  display: inline-block;
  margin: 0 10px -20px; }

@page {
  size: A4;
  margin: 10%; }

html {
  font-style: 7px; }

body {
  color: #6C757A; }

h1, h2, h3, h4, h5 {
  margin-bottom: 20px; }

.printDisplay {
  display: block; }

nav,
.visite,
.reseaux,
.cookies,
.back,
.bien-info .bloc-recherche a.block-contact,
.contact,
.etape-epargne1,
.etape-epargne2-3,
.add-biens,
.rv,
.recap,
.btn-resultat,
.choix,
.node-type-espace-investisseur .fd_titre .wrap .choix,
.node-type-espace-investisseur .bloc-epargne .col-right .link-action,
.node-type-espace-investisseur .bloc-epargne form .btn-resultat,
.node-type-espace-investisseur .bloc-epargne form p label img,
.action-choices,
.link-action,
.gallery-thumbs,
.avis-projetim,
.bottom,
.reseaux,
.instafeed,
footer,
.galerie,
.selection,
.logo2,
.newsletter,
.map-info-bien {
  display: none; }

.display-print {
  display: block;
  font-size: 1.5em; }
  .display-print h2 {
    font-family: 'satisfy';
    font-size: 2em;
    margin-bottom: 10px; }
  .display-print .webform-component--texte a, .display-print .node-type-contact .bloc-info a {
    margin: 0;
    padding: 0; }

.node-type-mes-biens .display-print {
  margin-left: -150px; }

main section {
  padding-right: 0;
  padding-left: 0; }

.intro {
  width: 100%;
  display: table; }

h1,
p.prix {
  display: inline-block;
  width: auto !important; }

.prix:before {
  content: ' - '; }

.logo {
  margin-bottom: 20px; }

.bien-info {
  padding: 30px 0;
  font-size: 1rem; }
  .bien-info .flex-invest ul {
    width: 45%; }
  .bien-info ul {
    width: 31%;
    margin-bottom: 30px;
    display: inline-block;
    vertical-align: top; }
    .bien-info ul li {
      list-style-type: none;
      width: 100%;
      border-bottom: 1px solid #6C757A !important;
      padding: 6px 0;
      display: table; }
      .bien-info ul li:first-child {
        border-top: 1px solid #6C757A !important; }
      .bien-info ul li strong {
        float: right;
        max-width: 50%;
        text-align: right; }
      .bien-info ul li .date-display-single {
        font-weight: 700; }
    .bien-info ul:before {
      content: '';
      font-family: "icomoon";
      font-size: 3.2rem;
      display: block;
      text-align: center;
      color: #cac7bc;
      margin-bottom: 20px; }
    .bien-info ul:nth-child(1):before {
      content: '\e916'; }
    .bien-info ul:nth-child(2):before {
      content: '\e91d'; }
    .bien-info ul:nth-child(3):before {
      content: '\e91e'; }
    .bien-info ul:nth-child(4):before {
      content: '\e91b'; }

.bien-neuf {
  font-weight: 700;
  color: #00ABC5; }

.detail-top p {
  width: 100%; }

.image-bien img {
  width: 200px;
  height: auto;
  vertical-align: top;
  margin-bottom: 50px; }

.node-type-espace-investisseur .investisseur .bloc-2-col,
.node-type-espace-investisseur .investisseur .prise-rendez-vous-invest,
.node-type-espace-investisseur .investisseur .blog-slider,
.node-type-espace-investisseur .investisseur .resultat-calculateur,
.node-type-espace-investisseur .investisseur .js-show-calculator-result,
.info-projetim {
  display: none; }

.calculateur-epargne .remplissez-desktop,
.calculateur-epargne .remplissez-mobile,
.calculateur-epargne .icon-question2,
.calculateur-epargne .bulle,
.calculateur-epargne .sstitre,
.calculateur-epargne .camembert {
  display: none; }

.calculateur-epargne .titre4 {
  font-size: 20px; }

.chart_investisseur {
  display: table; }
  .chart_investisseur p {
    width: 90%;
    padding: 0 5%;
    display: inline-block;
    float: left;
    position: relative; }
  .chart_investisseur label {
    font-size: 12px;
    width: 68%;
    margin-right: 1%;
    float: left;
    background-color: #8F4579;
    padding: 7px 10px 8px;
    color: #ffffff;
    text-transform: uppercase; }
  .chart_investisseur input {
    font-size: 12px;
    width: 30%;
    float: right;
    color: #8F4579;
    margin-bottom: 5px;
    padding: 7px 10px;
    border: 0 none; }
  .chart_investisseur .resultat label {
    font-weight: bold; }
  .chart_investisseur .resultat input {
    font-weight: bold; }
  .chart_investisseur .flex-item {
    float: left; }
