.noscroll{
    overflow: hidden;
}
.left{
    float: left;
}
.right{
    float: right;
}
main{
    section, article{
        padding: 0 30px;
        p{
            margin-bottom: 15px;
        }
        a{
            color: $bleu;

            &:hover{
                text-decoration: underline;
            }
        }
        ul{
            //margin-left: 30px;
            margin-bottom: 15px;
        }
    }
    section article{
        padding: 0;
    }
}
.titre1{
    font-family: $satisfy;
    font-size: 6rem;
    line-height: 7.1rem;
    color: $blanc;
    font-weight: 400;
}
.titre2{
    font-family: $asap;
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.6rem;
    margin-bottom: 5px;
}
.titre2-invest{
    color: $violet;
    font-family: $satisfy;
    font-size: 3.2rem;
    line-height: 3.8rem;
    font-weight: 400;
    margin-bottom: 20px;
}
.titre3{
    font-family: $satisfy;
    font-size: 3.2rem;
    line-height: 3.8rem;
    font-weight: 400;
}
.titre4{
    font-family: $asap;
    font-weight: 700;
    font-size: 2.7rem;
    //color: $bleu;
    margin-bottom: 10px;
    //margin-top: 25px;
    text-transform: uppercase;
}
.titre5{
    font-size: 2.25em;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 25px;

}
.titre6{
    font-size: 1.95rem;
    font-weight: 700;
}
.legende{
    color: $gris5;
    margin-bottom: 25px;
    font-size: 1.4rem;
    margin-top: -5px;
}
.citation{
    font-style: italic;
    font-size: 1.8rem;
    border-left: 2px solid $bleu;
    padding-left: 20px;
    line-height: 2.6rem;
    margin: 20px 0 20px 40px;
}
.exergue{
    font-size: 2rem;
    line-height: 2.8rem;
}
.erreur{
    font-size: 1.7em;
    text-align: center;
    padding: 25px 0;
    font-family: $satisfy;
}


.btn{
    font-size: 1.4rem;
    padding: 8px 18px 8px 18px;
    border: 1px solid $gris;
    display: inline-block;
    text-transform: uppercase;
    margin-top: 18px;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    z-index: 10;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: -50px;
        bottom: 0;
        left: 0;
        border-right: 50px solid transparent;
        border-bottom: 80px solid $blanc;
        transform: translateX(-100%);
        transition: 0.3s;
        z-index: -1;
    }
    &.btn-bleu{
        background-color: $bleu;
        border-color: $bleu;
        color: $blanc;
        transition: 0.2s;

        &:hover{
            background-color: darken($bleu, 10%);
            border-color: darken($bleu, 10%);
            color: $bleu;
        }
    }
    &.btn-blanc{
        border-color: $blanc;
        color: $blanc;

        &:hover{
            &:before{
                border-bottom-color: $blanc;
            }
        }
    }
    &.btn-gris{
        border-color: $gris;
        color: $gris;

        &:hover{
            color: $blanc;

            &:before{
                border-bottom-color: $gris;
            }
        }
    }
    &:hover{
        text-decoration: none;

        &:before{
            transform: translateX(0);
        }
    }
}

.violet{
    a .titre,
    a:before{
        color: $violet;
    }
    .btn:hover{
        color: $blanc;
        border-color: $violet;
    }
    .btn:hover:before{
        border-bottom-color: $violet;
    }
}
.jaune{
    a .titre,
    a:before{
        color: $jaune;
    }
    .btn:hover{
        color: $blanc;
        border-color: $jaune;
    }
    .btn:hover:before{
        border-bottom-color: $jaune;
    }
}
.bleu{
    a .titre,
    a:before{
        color: $bleu;
    }
    .btn:hover{
        color: $blanc;
        border-color: $bleu;
    }
    .btn:hover:before{
        border-bottom-color: $bleu;
    }
}
.vert{
    a .titre,
    a:before{
        color: $vert;
    }
    .btn:hover{
        color: $blanc;
        border-color: $vert;
    }
    .btn:hover:before{
        border-bottom-color: $vert;
    }
}

.fd_titre{
    padding: 56px 15px 30px;
    margin-bottom: 30px;
    text-align: center;
    color: $grisf;
    z-index: 10;
    margin-top: -30px;
    background-color: $fdgris;

    .titre2{
        color: $grisf;
    }

}
.front .fd_titre{
    position: absolute;
    left: -9999px;
}

.txt-bleu{
    color: $bleu;
}

.row{
    display: table;
    width: 100%;

    .col{
        display: table-cell;
    }
}
// .flex{
//     display: inline-block;
//     display: flex;
//     justify-content: space-between;
// }
.flex{
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 30px;
    justify-content: space-between;
    //align-items: flex-start;
}
.flex-item{
    display: inline-block;
    list-style-type: none;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}
.bg-blanc{
    background-color: $blanc;
}
.bg-jaune{
    background-color: $jaune;
}
.bg-bleu{
    background-color: $bleu;
}
.bg-gris{
    background-color: $fdgris;
}
.bg-violet{
    background-color: $violet;
    color: $blanc;
}


[class*="pattern-"],
.fd_titre{
    position: relative;

    &:before{
        position: absolute;
        top: -5px;
        left: 0;
        width: 100%;
        height: 5px;
        content: '';
        display: inline-block;
        background-repeat: repeat-x;
        background-position: left top;
    }
    &:after{
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 5px;
        content: '';
        display: inline-block;
        background-repeat: repeat-x;
        background-position: left bottom;
    }
}
.pattern-gris-top:before{
    background-image: url(../images/pattern-gris-top.svg);
}
.pattern-gris-bottom:after,
.fd_titre:after{
    background-image: url(../images/pattern-gris-bottom.svg);
}
.pattern-jaune-top:before{
    background-image: url(../images/pattern-jaune-top.svg);
}
.pattern-jaune-bottom:after{
    background-image: url(../images/pattern-jaune-bottom.svg);
}
.pattern-blanc-top:before{
    background-image: url(../images/pattern-blanc-top.svg);
}
.pattern-blanc-bottom:after{
    background-image: url(../images/pattern-blanc-bottom.svg);
}
.pattern-bleu-top:before{
    background-image: url(../images/pattern-bleu-top.svg);
}
.pattern-bleu-bottom:after{
    background-image: url(../images/pattern-bleu-bottom.svg);
}


[class*="barre-"],
.barre-horizontale{ //separateur ligne avec rond de chaque cote
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: lighten($gris, 25%);
    left: 0;
    z-index: 100;

    &:before,
    &:after{
        position: absolute;
        top: -3.5px;
        width: 8px;
        height: 8px;
        background-color: lighten($gris, 25%);
        content: '';
        display: inline-block;
        border-radius: 100%;
    }
    &:before{
        left: 0;
    }
    &:after{
        right: 0;
    }
}

.picto-station{
    background-color: $bleu;
    border-radius: 100%;
    color: $blanc;
    text-transform: uppercase;
    display: inline-block;
    width: 15px;
    text-align: center;
    padding: 2px 0;
    font-size: 0.95rem;
    font-weight: 700;
    margin-top: 5px;

    &.mA{
        background-color: $apricot;
    }
    &.mB{
        background-color: $jaune;
    }
    &.mC{
        background-color: $violet2;
    }
}

.hide{
    display: none;
}
.nopadding{
    padding: 0;
}








.pager{
    text-align: center;
    margin-bottom: 50px;

    li{
        list-style-type: none;
        display: inline-block;
        border-left:1px solid $fdgris;
        font-size: 1.2rem;
        color: $bleu;
        text-align: center;
        padding: 2px 10px;

        &:nth-child(1),
        &.pager-prev,
        &.pager-last{
            border-color: $blanc;
        }
        &.pager-current{
            color: $fdgris;
        }
    }
}

.camembert{
    background-image: url(../images/camembert.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 60px;
    height: 60px;
    display: inline-block;
    margin: 0 10px -20px;
}
