@import '_variables';
@import '_normalize';
@import '_commun';

@page {
    size: A4;
    margin: 10%;
}
html{
    font-style: 7px;
}
body{
    color: $gris;
}
h1, h2, h3, h4, h5 {
  //page-break-after: avoid;
  margin-bottom: 20px;
}

.printDisplay{
    display: block;
}
nav,
.visite,
.reseaux,
.cookies,
.back,
.bien-info .bloc-recherche a.block-contact,
.contact,
.etape-epargne1,
.etape-epargne2-3,
.add-biens,
.rv,
.recap,
.btn-resultat,
.choix,
.node-type-espace-investisseur .fd_titre .wrap .choix,
.node-type-espace-investisseur .bloc-epargne .col-right .link-action,
.node-type-espace-investisseur .bloc-epargne form .btn-resultat,
.node-type-espace-investisseur .bloc-epargne form p label img,
.action-choices,
.link-action,
.gallery-thumbs,
.avis-projetim,
.bottom,
.reseaux,
.instafeed,
footer,
.galerie,
.selection,
.logo2,
.newsletter,
.map-info-bien{
    display: none;
}
.display-print{
    display: block;
    font-size: 1.5em;

    h2{
        font-family: 'satisfy';
        font-size: 2em;
        margin-bottom: 10px;
    }
    .webform-component--texte a, .node-type-contact .bloc-info a{
        margin: 0;
        padding: 0;
    }
}
.node-type-mes-biens .display-print{
    margin-left: -150px;

}
main section{
    padding-right: 0;
    padding-left: 0;
}
.intro{
    width: 100%;
    display: table;
}
h1,
p.prix{
    @extend .titre2;
    display: inline-block;
    //float: left;
    width: auto !important;
}
.prix{
    &:before{
        content: ' - ';
    }
}

.logo{
    margin-bottom: 20px;
}



.bien-info{
    padding: 30px 0;
    font-size: 1rem;
    //page-break-after: always;

    .flex-invest{
        ul{
            width: 45%;
        }
    }
    ul{
        width: 31%;
        margin-bottom: 30px;
        display: inline-block;
        vertical-align: top;

        li{
            list-style-type: none;
            width: 100%;
            border-bottom: 1px solid $gris !important;
            padding: 6px 0;
            display: table;

            &:first-child{
                border-top: 1px solid $gris !important;
            }
            strong{
                float: right;
                max-width: 50%;
                text-align: right;
            }
            .date-display-single{
                font-weight: 700;
            }
        }
        &:before{
            content: '';
            font-family: $projetim;
            font-size: 3.2rem;
            display: block;
            text-align: center;
            color: darken($fdgris, 15%);
            margin-bottom: 20px;
        }
        &:nth-child(1){
            &:before{
                content: '\e916';
            }
        }
        &:nth-child(2){
            &:before{
                content: '\e91d';
            }
        }
        &:nth-child(3){
            &:before{
                content: '\e91e';
            }
        }
        &:nth-child(4){
            &:before{
                content: '\e91b';
            }
        }
    }
}
.bien-neuf{
    font-weight: 700;
    color: $bleu;
}
.detail-top{
    p{
        width: 100%;
    }
}
// .map{
//     width: 18cm;
//     height: 500px;
//     margin-bottom: 50px;
//     //page-break-before: always;
// }
.image-bien img{
    width: 200px;
    height: auto;
    vertical-align: top;
    margin-bottom: 50px;
}

.node-type-espace-investisseur .investisseur .bloc-2-col,
.node-type-espace-investisseur .investisseur .prise-rendez-vous-invest,
.node-type-espace-investisseur .investisseur .blog-slider,
.node-type-espace-investisseur .investisseur .resultat-calculateur,
.node-type-espace-investisseur .investisseur .js-show-calculator-result,
.info-projetim
{
    display: none;
}

.calculateur-epargne {
    .remplissez-desktop,
    .remplissez-mobile,
    .icon-question2,
    .bulle,
    .sstitre,
    .camembert {
        display: none;
    }
    .titre4 {
        font-size: 20px;
    }
}

.chart_investisseur {
    display: table;

    p {
        width: 90%;
        padding: 0 5%;
        display: inline-block;
        float: left;
        position: relative;
    }

    label {
        font-size: 12px;
        width: 68%;
        margin-right: 1%;
        float: left;
        background-color: #8F4579;
        padding: 7px 10px 8px;
        color: #ffffff;
        text-transform: uppercase;
    }

    input {
        font-size: 12px;
        width: 30%;
        float: right;
        color: #8F4579;
        margin-bottom: 5px;
        padding: 7px 10px;
        border: 0 none;
    }

    .resultat {
        label {
            font-weight: bold;
        }
        input {
            font-weight: bold;
        }
    }

    .flex-item {
        float: left;
    }


}